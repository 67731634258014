
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class DeviceDetail extends Vue {
  private activeName = 'base'

  created () {
    this.activeName = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
  }

  handleClick () {
    const path = `/assestsManage/detail/${this.$route.params.deviceId}/${this.activeName}`
    if (path !== this.$route.path) {
      this.$router.replace({ path: path })
    }
  }
}
